@keyframes background_fade {
    0% {
        background-color: lightslategrey;
    }

    100% {
        background-color: white;
    }
}

@keyframes background_fade_mobile {
    0% {
        background-color: lightslategrey;
    }

    100% {
        background-color: white;
    }
}

@keyframes menu {
    0% {
        transform: translateY(-15vw);
    }
    40% {
        transform: translateY(-15vw);
    }
    80% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes menu_mobile {
    0% {
        transform: translateY(-15vw);
    }
    40% {
        transform: translateY(-15vw);
    }
    80% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes contact {
    0% {
        transform: translateY(15vw);
    }
    40% {
        transform: translateY(15vw);
    }
    80% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes contact_mobile {
    0% {
        transform: translateY(15vw);
    }
    40% {
        transform: translateY(15vw);
    }
    80% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes woody {
    0% {
        background-color: lightslategrey;
        color: rgba(119, 136, 153, 0);
        transform: translateX(10vw);
    }
    50% {
        color: rgba(119, 136, 153, 0);
        transform: translateX(10vw);
    }
    100% {
        background-color: white;
        color: rgba(119, 136, 153, 255);
        transform: translateX(0px);
    }
}

@keyframes lai {
    0% {
        transform: translateX(-10vw);
    }
    50% {
        transform: translateX(-10vw);
    }
    100% {
        transform: translateX(0px);
    }
}

@keyframes woody_mobile {
    0% {
        background-color: lightslategrey;
        color: rgba(119, 136, 153, 0);
        transform: translateY(13vw);
    }
    50% {
        color: rgba(119, 136, 153, 0);
        transform: translateY(13vw);
    }
    100% {
        background-color: white;
        color: rgba(119, 136, 153, 255);
        transform: translateY(0px);
    }
}

@keyframes lai_mobile {
    0% {
        transform: translateY(-13vw);
    }
    50% {
        transform: translateY(-13vw);
    }
    100% {
        transform: translateY(-3vw);
    }
}

.background_fade {
    animation-name: background_fade;
    animation-duration: 4s;

    @media screen and (max-width: 800px) {
        animation-name: background_fade_mobile;
        animation-duration: 4s;
    }
}

.woody {
    animation-name: woody;
    animation-duration: 4s;

    @media screen and (max-width: 800px) {
        animation-name: woody_mobile;
        animation-duration: 4s;
    }
}

.lai {
    animation-name: lai;
    animation-duration: 4s;

    @media screen and (max-width: 800px) {
        animation-name: lai_mobile;
        animation-duration: 4s;
    }
}

body {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.menu {
    padding-top: 2vw;
    padding-bottom: 2vw;
    font-size: 1.5vw;
    font-weight: 500;
    text-decoration: underline;
    color: lightslategrey;
    animation: menu;
    animation-duration: 8s;
    display: flex;
    justify-content: center;

    a {
        color: lightslategrey;
    }

    a:hover {
        color: black;
        transition: color 1s ease;
        cursor: pointer;
    }

    @media screen and (max-width: 800px) {
        padding-top: 5vw;
        padding-bottom: 5vw;
        font-size: 5vw;
        animation: menu_mobile;
        animation-duration: 8s;
    }
}

.text {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    margin-right: -50%;
    display: flex;
    font-size: 10vw;

    @media screen and (max-width: 800px) {
        flex-direction: column;
        text-align: center;
        font-size: 20vw;
        flex-wrap: wrap;
    }

    #woody {
        font-weight: bolder;
        color: lightslategrey;
        z-index: 1;
    }

    #lai {
        color: black;
        z-index: -1;
        padding-left: 2vw;

        @media screen and (max-width: 800px) {
            padding-left: 0;
            transform: translateY(-3vw);
        }
    }
}

.contact {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-top: 2vw;
    padding-bottom: 2vw;
    font-size: 1.5vw;
    font-weight: 500;
    text-decoration: underline;
    color: lightslategrey;
    animation: contact;
    animation-duration: 8s;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
        color: lightslategrey;
    }

    a:hover {
        color: black;
        transition: color 1s ease;
        cursor: pointer;
    }

    @media screen and (max-width: 800px) {
        padding-top: 5vw;
        padding-bottom: 5vw;
        font-size: 5vw;
        animation: contact_mobile;
        animation-duration: 8s;
    }
}
